import { gql } from '@apollo/client/core';
import { useQuery } from '@vue/apollo-composable';
import { useNotification } from '@/modules/core';
import { computed } from 'vue';

export function usePriceListUpdate(id) {
  const { error } = useNotification();

  const { result, loading, refetch, onError } = useQuery(PRICE_LIST_UPDATE_QUERY, { id });
  const priceListUpdate = computed(() => result?.value?.priceListUpdate || {});

  onError((err) => {
    console.error('usePriceListUpdate', err);
    error();
  });

  return {
    priceListUpdate,
    loading,
    refetch,
  };
}

export function usePriceListUpdates(variables, options) {
  const { error } = useNotification();

  const { result, loading, refetch, onError } = useQuery(PRICE_LIST_UPDATES_QUERY, variables, options);
  const priceListUpdates = computed(() => result?.value?.priceListUpdates || []);

  onError((err) => {
    console.error('usePriceListUpdates', err);
    error();
  });

  return {
    priceListUpdates,
    loading,
    refetch,
  };
}

export const PRICE_LIST_UPDATE_FILEPATH_QUERY = gql`
  query priceListUpdate($id: ID) {
    priceListUpdate(id: $id) {
      id
      filePath
      filePathUrl
    }
  }
`;

const PRICE_LIST_UPDATE_QUERY = gql`
  query priceListUpdate($id: ID) {
    priceListUpdate(id: $id) {
      id
      businessId
      priceListId
      name
      description
      updateDate
      filePathUrl
      ocr {
        google {
          status
          generalPath
          generalPathUrl
        }
      }
      sections {
        name
        productIds
        sections
      }
      changes {
        productId
        price
      }
      priceList {
        name
        prices {
          id
          productId
          data {
            date
            price
          }
        }
      }
    }
  }
`;

const PRICE_LIST_UPDATES_QUERY = gql`
  query priceListUpdates($recorded: Boolean, $businessId: ID!) {
    priceListUpdates(recorded: $recorded, businessId: $businessId) {
      id
      businessId
      priceListId
      createdAt
      createdBy {
        profile {
          firstName
          lastName
        }
      }
      filePath
    }
  }
`;

export const UPDATE_PRICE_LIST_UPDATE_MUTATION = gql`
  mutation priceListUpdateUpdate($id: ID!, $updateParams: PriceListUpdateUpdateParams!) {
    priceListUpdateUpdate(id: $id, updateParams: $updateParams) {
      id
      name
      description
      updateDate
      priceListId
      changes {
        productId
        price
      }
      sections {
        name
        productIds
        sections
      }
    }
  }
`;

export const RECORD_PRICE_LIST_UPDATE_MUTATION = gql`
  mutation priceListUpdateRecord($id: ID!) {
    priceListUpdateRecord(id: $id)
  }
`;

export const REQUEST_OCR_PRICE_LIST_UPDATE_MUTATION = gql`
  mutation priceListUpdateRequestOcr($id: ID!) {
    priceListUpdateRequestOcr(id: $id)
  }
`;

export const CREATE_PRICE_LIST_UPDATE_MUTATION = gql`
  mutation priceListUpdateCreate($filePath: String, $priceListId: ID, $businessId: ID!) {
    priceListUpdateCreate(filePath: $filePath, priceListId: $priceListId, businessId: $businessId) {
      id
    }
  }
`;

export const PRICE_LIST_UPDATE_FILE_UPLOAD_QUERY = gql`
  query priceListUpdateFileUpload($filename: String!, $businessId: ID!) {
    priceListUpdateFileUpload(filename: $filename, businessId: $businessId) {
      filePath
      url
      fields
    }
  }
`;
