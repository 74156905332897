<template>
  <div>
    <div class="card d-flex flex-row py-1 px-2 overflow-auto">
      <div v-for="(level, sectionLevel) in levels" :key="`level-${sectionLevel + 1}`" class="d-flex">
        <div>
          <div class="d-flex justify-content-between align-items-center">
            <p class="fw-bold">
              {{ $t('priceListManagement.priceListUpdate.sections.level', { level: sectionLevel + 1 }) }}
            </p>
            <el-popover placement="top-start" width="150" trigger="click" :visible-arrow="false" popper-class="p-4">
              <div
                class="d-flex align-items-center text-typography-primary clickable"
                @click="openNameModal(NAME_MODAL_TYPES.CREATE, { sectionLevel })"
              >
                <PlusIcon />
                <div class="mx-1" />
                <p>{{ $t('priceListManagement.priceListUpdate.sections.addSection') }}</p>
              </div>
              <Button slot="reference" type="text" class="p-0 settings-level">
                <KebabIcon />
              </Button>
            </el-popover>
          </div>
          <ul class="list-group overflow-auto" :style="{ height: '150px' }">
            <li v-if="sectionLevel === 0" class="list-group p-1" @click="currentPath = []">
              <div
                class="rounded section-item"
                :class="{ ['active-folder']: !currentPath.length }"
                :style="{ width: '200px' }"
              >
                <p class="px-2">{{ $t('priceListManagement.priceListUpdate.sections.noSection') }}</p>
              </div>
            </li>
            <li
              v-for="(section, sectionIndex) in level"
              :key="`section-${section.name}-level-${sectionLevel + 1}`"
              class="list-group p-1"
              @click="handleSelect(sectionLevel, sectionIndex)"
            >
              <div
                class="d-flex justify-content-between align-items-center rounded section-item"
                :class="getStateStyle(sectionLevel, sectionIndex)"
                :style="{ width: '200px' }"
              >
                <p class="px-2">{{ section.name }}</p>
                <div class="d-flex" @click.stop>
                  <el-popover
                    class="d-flex section-settings"
                    :class="{ ['px-1']: !(section.sections && section.sections.length) }"
                    placement="top-start"
                    width="150"
                    trigger="click"
                    :visible-arrow="false"
                    popper-class="p-4"
                  >
                    <div class="text-typography-primary">
                      <div
                        class="d-flex align-items-center mb-2 clickable"
                        @click="
                          openNameModal(NAME_MODAL_TYPES.EDIT, { sectionLevel, sectionIndex, name: section.name })
                        "
                      >
                        <EditIcon />
                        <div class="mx-1" />
                        <p>{{ $t('priceListManagement.priceListUpdate.sections.editSectionName') }}</p>
                      </div>
                      <div
                        class="d-flex align-items-center clickable"
                        @click="sectionToDelete = { sectionLevel, sectionIndex, name: section.name }"
                      >
                        <TrashCanIcon />
                        <div class="mx-1" />
                        <p>{{ $t('priceListManagement.priceListUpdate.sections.deleteSection') }}</p>
                      </div>
                    </div>
                    <Button slot="reference" type="text" class="p-0 border-0 settings-section-icon">
                      <SettingsSmallIcon />
                    </Button>
                  </el-popover>
                  <ChevronIcon
                    v-if="section.sections && section.sections.length"
                    class="chevron-icon"
                    height="16px"
                    width="16px"
                    :direction="$direction === 'ltr' ? 'right' : 'left'"
                  />
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="mx-2" :class="{ ['divider']: sectionLevel !== levels.length - 1 }" />
      </div>
      <div v-if="showAddLevelButton" :style="{ width: '200px' }">
        <div class="d-flex justify-content-between add-level">
          <p class="fw-bold">
            {{ $t('priceListManagement.priceListUpdate.sections.addLevel') }}
          </p>
          <Button
            type="text"
            class="p-0 border-0"
            @click="openNameModal(NAME_MODAL_TYPES.CREATE, { sectionLevel: currentPath.length })"
          >
            <PlusIcon class="text-typography-primary" />
          </Button>
        </div>
      </div>
    </div>
    <el-dialog
      v-if="sectionToDelete"
      visible
      width="30%"
      custom-class="rounded"
      :before-close="() => (sectionToDelete = null)"
    >
      <template #title>
        <h2>
          {{ $t('priceListManagement.priceListUpdate.sections.deleteModal.title') }}
        </h2>
      </template>
      <p class="text-typography-primary">
        {{
          $t('priceListManagement.priceListUpdate.sections.deleteModal.deleteSection', {
            name: sectionToDelete && sectionToDelete.name,
          })
        }}
      </p>
      <p class="text-typography-primary">
        {{ $t('priceListManagement.priceListUpdate.sections.deleteModal.deleteImpact') }}
      </p>
      <span slot="footer" class="dialog-footer">
        <Button type="secondary" @click="sectionToDelete = null">
          {{ $t('commons.cancel') }}
        </Button>
        <Button type="primary" @click="handleDeleteSection">
          {{ $t('commons.apply') }}
        </Button>
      </span>
    </el-dialog>
    <el-dialog
      v-if="modalNameType"
      visible
      width="30%"
      custom-class="rounded"
      :before-close="() => (modalNameType = null)"
    >
      <template #title>
        <h2>
          {{ $t(`priceListManagement.priceListUpdate.sections.nameModal.${modalNameType}`) }}
        </h2>
      </template>
      <div class="row">
        <div class="pt-2" :class="$direction === 'rtl' ? 'pe-4' : 'ps-4'">
          <p class="text-typography-primary">
            {{ $t('priceListManagement.priceListUpdate.sections.nameModal.sectionName') }}
          </p>
        </div>
        <div class="col">
          <el-form
            ref="sectionForm"
            :rules="sectionNameFormRules"
            :model="sectionForm"
            @submit.native.prevent="submitSectionForm"
          >
            <el-form-item prop="name">
              <el-input v-model="sectionForm.name" />
            </el-form-item>
          </el-form>
        </div>
      </div>
      <template #footer>
        <Button type="secondary" @click="modalNameType = null">{{ $t('commons.cancel') }}</Button>
        <Button @click="submitSectionForm">{{ $t('commons.apply') }}</Button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { isNil, clone } from 'ramda';

import { ChevronIcon, PlusIcon, SettingsSmallIcon, KebabIcon, EditIcon, TrashCanIcon } from '@/assets/icons';
import { Button } from '@/modules/core';

import { getSectionsByLevel } from '../tools/sectionTree';

const addSection = (currentPath, sectionsTrees, sectionLevel, sectionData) => {
  const sections = clone(sectionsTrees);
  const subSections = getSectionsByLevel(currentPath, sections, sectionLevel);
  subSections.push(sectionData);
  return sections;
};

const editSection = (currentPath, sectionsTrees, sectionLevel, sectionIndex, sectionData) => {
  const sections = clone(sectionsTrees);
  const subSections = getSectionsByLevel(currentPath, sections, sectionLevel);
  subSections[sectionIndex] = { ...subSections[sectionIndex], ...sectionData };
  return sections;
};

const deleteSection = (currentPath, sectionsTrees, sectionLevel, sectionIndex) => {
  const sections = clone(sectionsTrees);
  const subSections = getSectionsByLevel(currentPath, sections, sectionLevel);
  subSections.splice(sectionIndex, 1);
  return sections;
};
const NAME_MODAL_TYPES = {
  EDIT: 'edit',
  CREATE: 'create',
};

export default {
  components: {
    ChevronIcon,
    PlusIcon,
    SettingsSmallIcon,
    KebabIcon,
    EditIcon,
    TrashCanIcon,
    Button,
  },
  props: {
    sections: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      NAME_MODAL_TYPES,
      sectionForm: { sectionLevel: -1, sectionIndex: -1, name: null },
      sectionToDelete: null,
      modalNameType: null,
      showAddLevelButton: false,
      currentPath: [],
      sectionNameFormRules: {
        name: [
          {
            validator: (_, value, callback) => {
              if (isNil(value) || value === '') {
                callback(
                  new Error(this.$t('priceListManagement.priceListUpdate.sections.nameModal.sectionFormRule.required'))
                );
              } else if (!this.isSectionNameUniqueToItsLevel(this.sectionForm.sectionLevel, value)) {
                callback(
                  new Error(this.$t('priceListManagement.priceListUpdate.sections.nameModal.sectionFormRule.unique'))
                );
              } else {
                callback();
              }
            },
            trigger: 'blur',
          },
        ],
      },
    };
  },
  computed: {
    levels() {
      const sections = clone(this.sections);
      const levels = [[...sections]];
      let currentLevel = [...sections];
      for (const index of this.currentPath) {
        const sub = currentLevel[index];
        if (!sub?.sections?.length) break;
        levels.push(sub.sections);
        currentLevel = sub.sections;
      }
      return levels;
    },
  },
  watch: {
    currentPath() {
      if (!this.currentPath.length) this.showAddLevelButton = false;
      else {
        const selectedSectionIndex = this.currentPath.length;
        const sections = getSectionsByLevel(this.currentPath, this.sections, selectedSectionIndex);
        this.showAddLevelButton = !sections.length;
      }

      this.$emit('change', this.currentPath);
    },
    sections() {
      this.sectionToDelete = null;
      this.modalNameType = null;
    },
    modalNameType(value) {
      if (isNil(value)) this.sectionForm = { sectionLevel: -1, sectionIndex: -1, name: null };
    },
  },
  methods: {
    handleSelect(sectionLevel, sectionIndex) {
      this.currentPath = [...this.currentPath.slice(0, sectionLevel), sectionIndex];
    },
    getStateStyle(sectionLevel, sectionIndex) {
      const index = this.currentPath[sectionLevel];
      if (index === sectionIndex) {
        if (sectionLevel === this.currentPath.length - 1) return 'active-folder';
        return 'active-path';
      }
      return;
    },
    handleDeleteSection() {
      const { sectionLevel, sectionIndex } = this.sectionToDelete;
      const sections = deleteSection(this.currentPath, this.sections, sectionLevel, sectionIndex);
      const newPath = [...this.currentPath];
      if (this.currentPath[sectionLevel] === sectionIndex) {
        newPath.splice(sectionLevel);
      } else if (this.currentPath[sectionLevel] > sectionIndex) {
        newPath[sectionLevel] = newPath[sectionLevel] - 1;
      }
      this.currentPath = newPath;
      this.$emit('update:sections', sections);
    },
    openNameModal(type, form) {
      this.modalNameType = type;
      this.sectionForm = { ...this.sectionForm, ...form };
    },
    submitSectionForm() {
      const { name, sectionLevel, sectionIndex } = this.sectionForm;
      this.$refs.sectionForm.validate((valid) => {
        let newSections;
        if (valid) {
          switch (this.modalNameType) {
            case this.NAME_MODAL_TYPES.CREATE:
              newSections = addSection(this.currentPath, this.sections, sectionLevel, { name });
              break;
            case this.NAME_MODAL_TYPES.EDIT:
              newSections = editSection(this.currentPath, this.sections, sectionLevel, sectionIndex, { name });
              break;
            default:
              break;
          }
          this.$emit('update:sections', newSections);
        } else {
          return false;
        }
      });
    },
    isSectionNameUniqueToItsLevel(sectionLevel, name) {
      const subSections = getSectionsByLevel(this.currentPath, this.sections, sectionLevel);
      return subSections.every((section) => section.name !== name);
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

.divider {
  width: 1px;
  background: $outline;
}

.section-item {
  &.active-folder {
    background: $primary;
    color: $white;
  }

  &.active-path {
    background: $secondary;
  }

  &:hover {
    cursor: pointer;
    .section-settings {
      visibility: visible;
    }
  }

  &:not(.active-folder) .chevron-icon {
    color: $typography-disabled;
  }

  .section-settings {
    visibility: hidden;
  }

  .settings-section-icon {
    color: $typography-secondary;

    &:hover {
      svg {
        color: $typography-primary;
        visibility: visible;
      }
      background: $secondary;
    }
  }
}

.add-level {
  color: $typography-disabled;
  svg {
    visibility: hidden;
  }
  &:hover {
    color: $typography-primary;
    svg {
      visibility: visible;
    }
  }
  button:hover {
    background: $secondary;
  }
}

.settings-level {
  color: $typography-secondary;
  &:hover {
    color: $typography-primary;
    background: $secondary;
  }
}

.clickable:hover {
  cursor: pointer;
  color: $primary;
}
</style>
