import { gql } from '@apollo/client/core';
import { useQuery } from '@vue/apollo-composable';
import { useNotification } from '@/modules/core';
import { computed } from 'vue';

export function usePriceList(id) {
  const { error } = useNotification();

  const { result, loading, onError } = useQuery(
    PRICE_LIST_QUERY,
    () => ({ id: id.value }),
    () => ({ enabled: !!id.value })
  );
  const priceList = computed(() => result?.value?.priceList || {});

  onError((err) => {
    console.error('usePriceList', err);
    error();
  });

  return {
    priceList,
    loading,
  };
}

const PRICE_LIST_QUERY = gql`
  query priceList($id: ID!) {
    priceList(id: $id) {
      id
      businessId
      name
      prices {
        id
        productId
        data {
          date
          price
        }
      }
      sections {
        name
        priceIds
        sections
      }
    }
  }
`;

export function usePriceLists(businessId) {
  const { error } = useNotification();

  const { result, loading, onError, refetch } = useQuery(PRICE_LISTS_QUERY, { businessId: businessId.value }, () => ({
    enabled: !!businessId.value,
  }));
  const priceLists = computed(() => result?.value?.priceLists || []);

  onError((err) => {
    console.error('usePriceLists', err);
    error();
  });

  return {
    priceLists,
    loading,
    refetch,
  };
}

const PRICE_LISTS_QUERY = gql`
  query priceLists($businessId: ID) {
    priceLists(businessId: $businessId) {
      id
      businessId
      name
      description
      productCount
      lastUpdate {
        name
        totalChanges
        updateDate
      }
    }
  }
`;

export const CREATE_PRICE_LIST_MUTATION = gql`
  mutation priceListCreate($createParams: PriceListCreateParams!) {
    priceListCreate(createParams: $createParams) {
      id
    }
  }
`;

export const PATCH_PRICE_LIST_MUTATION = gql`
  mutation priceListPatch($id: ID!, $patchParams: PriceListPatchParams!) {
    priceListPatch(id: $id, patchParams: $patchParams) {
      id
      name
      description
    }
  }
`;

export const DELETE_PRICE_LIST_MUTATION = gql`
  mutation priceListDelete($id: ID!) {
    priceListDelete(id: $id)
  }
`;
